<template>
  <div>
    <header class="header ml10 mt10" style="display: flex; justify-content: space-between; flex-wrap: wrap">
      <title-item title="Харилцагчын санал гомдол" />
      <el-select class="clssSel" v-model="selectedOutletID" @change="getComplains" placeholder="Select">
        <el-option
          v-for="item in $root.chosenOutlets"
          :key="item._id"
          :label="item.full_name_eng"
          :value="item._id">
        </el-option>
      </el-select>
    </header>
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="Шийдвэрлэгдээгүй" name="unresolvedComplain">
        <ComplainList type="'unresolved'" :complaintData="unresolvedComplains" :searchData="searchData" :getComplains="getComplains" :getDetail="getDetail" :changePage="changePage"/>
      </el-tab-pane>
      <el-tab-pane label="Шийдвэрлэгдсэн" name="resolvedComplain">
        <ComplainList type="'resolved'" :complaintData="resolvedComplains" :searchData="searchData" :getComplains="getComplains" :getDetail="getDetail" :changePage="changePage"/>
      </el-tab-pane>
    </el-tabs>
    <ComplainDetailDialog :isShow="isDialogShow" :closeFunction="closeDialog" @close="closeDialog" :dialogData="dialogData" :type="'solve000d'" :isOwner="false" />
    <el-drawer
      :title="'Гомдол ' + dialogData.id"
      :before-close="handleClose"
      :visible.sync="isDrawer"
      direction="ltr"
      size="90%"
      custom-class="demo-drawer"
      >
      <el-form
        :model="dialogData"
        label-width="140px"
        :rules="rules"
        style="margin-right:10px;"
        ref="dialogForm"
      >
        <el-form-item
          label="Гомдолын дугаар"
          size="small"
          style="margin-bottom: 5px"
        >
          <el-input
            disabled
            v-model="dialogData.id"
            style="width: 100px;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Утасны дугаар"
          size="small"
          style="margin-bottom: 5px"
        >
          <el-tag>{{ dialogData.phone }}</el-tag>
        </el-form-item>
        <el-form-item
          v-if="
            dialogData.complained_type != 'default' &&
              dialogData.complained_type != null &&
              dialogData.complained_to != null
          "
          :label="dialogData.complained_type == 'outlet' ? 'Салбар' : 'Жолооч'"
          size="small"
          style="margin-bottom: 5px"
        >
          <el-tag>{{ dialogData.complained_to }}</el-tag>
        </el-form-item>
        <el-form-item
          label="Шийдвэрлэсэн эсэх"
          size="small"
          style="margin-bottom: 5px"
        >
          <el-tag :type="dialogData.is_solved ? 'success' : ''">{{
            dialogData.is_solved ? "Шийдвэрлэсэн" : "Шийдвэрлээгүй"
          }}</el-tag>
        </el-form-item>
        <el-form-item
          label="Үндэслэлтэй эсэх"
          size="small"
          style="margin-bottom: 5px"
        >
        </el-form-item>
        <el-form-item
          label="Бүртгэсэн хэрэглэгч"
          size="small"
          style="margin-bottom: 5px"
        >
          <el-input
            disabled
            v-model="dialogData.created_user"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item label="Хариуцагч" size="small" style="margin-bottom: 5px">
          <el-input
            disabled
            v-model="dialogData.owner"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dialogData.complaint_1"
          style="margin-bottom: 5px"
          size="medium"
          label="Гомдлын төрөл:"
        >
          <el-tag>{{
            dialogData.complaint_1 +
              (dialogData.complaint_2 ? " -> " + dialogData.complaint_2 : "") +
              (dialogData.complaint_3 ? " -> " + dialogData.complaint_3 : "")
          }}</el-tag>
        </el-form-item>
        <el-form-item label="Тэмдэглэл" size="small">
          <el-input
            disabled
            type="textarea"
            v-model="dialogData.description"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import ComplainDetailDialog from '../complain/components/ComplainDetailDialog.vue'
import ComplainList from '../complain/components/ComplainList.vue'
import mAxios from '../../../helper/axiosInstance'
import dayjs from 'dayjs'
import TitleItem from '../../../components/items/titleItem.vue'
export default {
  name: 'Complain',
  components: {
    ComplainDetailDialog,
    ComplainList,
    TitleItem
  },
  data () {
    return {
      page: 1,
      allPartner: false,
      infoForm: null,
      loading: false,
      activeTabName: 'unresolvedComplain',
      resolvedComplains: {},
      unresolvedComplains: {},
      selectedOutletID: null,
      isDialogShow: false,
      dialogData: {},
      searchData: {
        start_date: dayjs().add(-14, 'day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        complained_value: ''
      },
      isDrawer: false,
      tempIds: [],
      rules: {
        solved_description: [
          {
            required: true,
            message: 'Шийдвэрлэсэн тэмдэглэлээ бичнэ үү.',
            trigger: 'blur'
          }
        ],
        selectedOutletID: [
          {
            required: true,
            message: 'Харилцагч сонгоно уу',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    changePage (page) {
      this.page = page
      this.getComplains()
    },
    getComplains () {
      if (this.selectedOutletID === null) {
        this.$notify.error({
          title: 'Анхааруулга',
          position: 'bottom-left',
          message: 'Харилцагч сонгоно уу',
          duration: 3000
        })
        return false
      }
      this.loading = true
      const tempIds = []
      if (this.allPartner) {
        this.$root.chosenOutlets.forEach(element => {
          tempIds.push(element._id)
        })
      }
      const payloadUnresolved = {
        page: this.page,
        is_solved: false,
        complained_value: [this.selectedOutletID],
        end_date: this.searchData.end_date,
        start_date: this.searchData.start_date
      }
      mAxios.post('complaint/get', payloadUnresolved).then(response => {
        if (response.data.status === 'success') {
          this.unresolvedComplains = response.data.data
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
        this.loading = false
      })
      const payloadResolved = {
        page: this.page,
        is_solved: true,
        complained_value: [this.selectedOutletID],
        start_date: this.searchData.start_date,
        end_date: this.searchData.end_date
      }
      mAxios.post('complaint/get', payloadResolved).then(response => {
        if (response.data.status === 'success') {
          this.resolvedComplains = response.data.data
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
        this.loading = false
      })
    },
    getDetail (type, row) {
      this.dialogData = row
      if (type === 'card') {
        this.isDrawer = true
      } else if (type === 'table') {
        this.isDialogShow = true
      }
    },
    closeDialog () {
      this.isDialogShow = false
      this.dialogData = {}
    },
    handleClose () {
      this.isDrawer = false
    }
  }
}
</script>
<style>
@media screen and (max-width: 600px) {
  .header {
    display: block;
    width: 100%;
  }
  .clssSel {
    width: 90%;
  }
}
</style>
