<template>
  <div>
    <div class="filter">
      <div class="input">
        <el-input
          :maxlength="7"
          v-model="searchData.order_number"
          @keyup.enter.native="getComplains()"
          placeholder="Захиалгын дугаар"
          clearable
        ></el-input>
      </div>
      <div class="date">
        <el-date-picker
          v-model="searchData.start_date"
          type="date"
          placeholder="Эхлэх огноо"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-date-picker
          v-model="searchData.end_date"
          type="date"
          placeholder="Дуусах огноо"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          class="ml10"
        >
        </el-date-picker>
        <el-button
          style="margin-left:1%;"
          type="info"
          @click="getComplains()"
          icon="el-icon-search"
          ></el-button
        >
      </div>
    </div>
    <div class="cardClss">
      <el-card style="width:100%; border-radius:5%; margin-bottom:2%;" v-for="complaint in complaintData.data" :key="complaint">
        <div style="justify-content: space-between; display: flex;">
          <span>#{{complaint.order_number}}</span>
          <span>{{complaint.created_at}}</span>
        </div>
        <h1>{{complaint.description}}</h1>
        <el-divider></el-divider>
        <div style="justify-content: space-between; display: flex; flex-direction:row;">
          <h2 style="color: #0BDA51; font-weight:bold;">{{complaint.complained_to}}</h2>
          <h3>{{complaint.complaint_1}}</h3>
        </div>
        <el-button @click="getDetail('card',complaint)" style="width:100%;">Дэлгэрэнгүй</el-button>
      </el-card>
    </div>
    <el-table class="tableClss" size="mini" style="width: 100%" :data="complaintData.data">
      <el-table-column prop="order_number" width="100%" label="З/дугаар"></el-table-column>
      <el-table-column prop="complaint_1" label="Гомдлын төрөл"></el-table-column>
      <el-table-column prop="complained_to" label="Салбар"></el-table-column>
      <el-table-column label="Тайлбар">
        <template slot-scope="scope">
          <read-more
            more-str="илүү унших..."
            less-str="хураах"
            :text="scope.row.description"
            :max-chars="70"
          ></read-more>
        </template>
      </el-table-column>
      <el-table-column prop="owner" label="Хариуцагч"></el-table-column>
      <el-table-column prop="created_at" label="Үүссэн огноо"></el-table-column>
      <el-table-column prop="solved_at" label="Ш/огноо"></el-table-column>
      <el-table-column label="Дэлгэрэнгүй" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="getDetail('table', scope.row)"
            >Дэлгэрэнгүй</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt20 mb20 text-right"
      background
      layout="total, prev, pager, next"
      :current-page="complaintData.current_page"
      :page-count="complaintData.total_page"
      :total="complaintData.total"
      :page-size="10"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: 'ComplainDetailDialog',
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  props: {
    changePage: Function,
    type: String,
    complaintData: Object,
    searchData: Object,
    getComplains: Function,
    getDetail: Function
  }
}
</script>
<style>
@media screen and (max-width: 600px) {
  .tableClss {
    display: none;
  }
  .cardClss {
    display:flex;
    flex-wrap:wrap
  }
  .filter {
    display:flex;
    flex-direction: column;
  }
  .input {
    width: 100%;
  }
  .date {
    margin-top: 2%;
    margin-bottom: 2%;
    display:flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 601px) {
  .filter {
    display:flex;
    flex-direction: row;
  }
  .input {
    width: 30%;
  }
  .date {
    margin-left: 1%;
    display:flex;
    flex-direction: row;
  }
  .cardClss {
    display: none;
  }
}
</style>
