<template>
  <el-dialog
    :title="'Гомдол ' + currentData.id"
    :visible.sync="isShow"
    width="50%"
    @close="closeFunction"
    @closed="closeFunction"
    :before-open="open"
    :before-close="closeFunction"
    @open="open"
  >
    <el-form
      :model="currentData"
      label-width="150px"
      :rules="rules"
      ref="dialogForm"
    >
      <el-form-item
        label="Гомдолын дугаар"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-input
          disabled
          v-model="currentData.id"
          style="width: 100px;"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Утасны дугаар"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-tag>{{ currentData.phone }}</el-tag>
      </el-form-item>
      <el-form-item
        v-if="
          currentData.complained_type != 'default' &&
            currentData.complained_type != null &&
            currentData.complained_to != null
        "
        :label="currentData.complained_type == 'outlet' ? 'Салбар' : 'Жолооч'"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-tag>{{ currentData.complained_to }}</el-tag>
      </el-form-item>
      <el-form-item
        label="Шийдвэрлэсэн эсэх"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-tag :type="currentData.is_solved ? 'success' : ''">{{
          currentData.is_solved ? "Шийдвэрлэсэн" : "Шийдвэрлээгүй"
        }}</el-tag>
      </el-form-item>
      <el-form-item
        label="Үндэслэлтэй эсэх"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-tag
          v-if="type == 'solved' || !isOwner"
          :type="currentData.is_reasonable ? 'success' : ''"
          >{{ currentData.is_reasonable ? "Тийм" : "Үгүй" }}</el-tag
        >
        <el-tooltip
          v-if="type == 'unsolved' && isOwner"
          :content="is_reasonable ? 'Үндэслэлтэй' : 'Үндэслэлгүй'"
          placement="top"
        >
          <el-switch
            v-model="currentData.is_reasonable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0"
          >
          </el-switch>
        </el-tooltip>
      </el-form-item>
      <el-form-item
        label="Бүртгэсэн хэрэглэгч"
        size="small"
        style="margin-bottom: 5px"
      >
        <el-input
          disabled
          v-model="currentData.created_user"
          style="width: 100%;"
        ></el-input>
      </el-form-item>
      <el-form-item label="Хариуцагч" size="small" style="margin-bottom: 5px">
        <el-input
          disabled
          v-model="currentData.owner"
          style="width: 100%;"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="currentData.complaint_1"
        style="margin-bottom: 5px"
        size="medium"
        label="Гомдлын төрөл:"
      >
        <el-tag>{{
          currentData.complaint_1 +
            (currentData.complaint_2 ? " -> " + currentData.complaint_2 : "") +
            (currentData.complaint_3 ? " -> " + currentData.complaint_3 : "")
        }}</el-tag>
      </el-form-item>
      <el-form-item label="Тэмдэглэл" size="small">
        <el-input
          disabled
          type="textarea"
          v-model="currentData.description"
          style="width: 100%;"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeFunction">Хаах</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ComplainDetailDialog',
  props: {
    isShow: Boolean,
    type: String,
    closeFunction: Function,
    dialogData: Object,
    title: String,
    isOwner: Boolean,
    solveFunction: Function
  },
  data () {
    return {
      currentData: {},
      is_reasonable: false,
      solved_description: '',
      rules: {
        solved_description: [
          {
            required: true,
            message: 'Шийдвэрлэсэн тэмдэглэлээ бичнэ үү.',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    open () {
      this.currentData = JSON.parse(JSON.stringify(this.dialogData))
    }
  }
}
</script>
